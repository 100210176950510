@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap);
/* custom colors  */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Signika Negative', sans-serif;
}

:root {
  --light-color: #e6e6f2;
  --sec-color: #000080;
  --bgcolor: #b9f2ff;
  /* --primary-color:  #4F336B; */
  --primary-color: #D83A4E;
  --text-color: #050505;
  --footer-background: #252527;
  --footer-text: #7D7D82;
}

html {
  font-size: 10px;
}

.navbar-toggler:focus
{
  outline: none !important;
  box-shadow: none!important;
}

button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
    outline: 0!important;
    outline-color: transparent!important;
    outline-width: 0!important;
    outline-style: none!important;
    border-radius: 0!important;
    box-shadow: 0 0.5rem 0.5rem var(--sec-color)!important;
}
.input-field:active.focus,
.input-field:focus,
.form-control:active.focus,
.form-control:focus, {
  outline: 2px solid #D83A4E !important;
}

/* .login-form .form-control{
  width: 100%;
} */
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: none!important;
}

a {
  text-decoration: none;
  color: #fff;
}
form_field{
  width: 500px;
}
/* --------------------------------- navbar css style starts
                        -------------------------------------------------------*/
.collapse{
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-bg {
  background-color: var(--bgcolor);
  padding: 0 0;
  z-index: 99;
  position: relative;
}

/* li.main-active{
  color: var(--primary-color);
} */

.navbar-brand {
  width: 19rem;
}

.navbar li {
  padding: 0 2rem;
}

.navbar li a {
  font-size: 1.8rem;
  font-weight: 400;
  transition: 0.5s;
  position: relative;
  /* color: var(--primary-color)!important; */
  color: #000;
  font-family: 'Signika Negative', sans-serif;
}

.navbar li a:before{
  content: '';
  position: absolute;
  left: 0;
  top: 100%;
  width: 0;
  height: 3px;
  background: var(--sec-color);
  transition: all 0.5s linear;
}

.navbar li a:hover:before{
  width: 50%;
  background-color: var(--sec-color);
}

.navbar li a:after{
  content: '';
  position: absolute;
  top: 100%;
  right: 0;
  width: 0;
  height: 3px;
  background: var(--sec-color);
  transition: all 0.5s linear;
}

.navbar li a:hover:after{
  width: 50%;
  background-color: var(--sec-color);
}

.navbar .btn-style {
  width: 100%!important;
  height: 4rem;
  /* color: #fff; */
  box-shadow: 0 0.5rem 0.5rem var(--sec-color);
  border-radius: 0.3rem!important;
}
@media screen and (max-width: 992px){
  .navbar .btn-style {
    width: 100%!important;
    height: 2rem;
    /* color: #fff; */
    box-shadow: 0 0.5rem 0.5rem var(--sec-color);
    border-radius: 0.3rem!important;
    font-size: 1.2rem!important;
  }
}

.btn-style:first-child {
    /*first btn*/
  color: var(--sec-color);
  background-color: transparent;
  font-size: 1.8em;
  font-weight: 400;
}

.btn-style:hover {
  border: 0.2rem solid var(--sec-color);
  box-shadow: none;
  border-radius: 0.3rem!important;
}

/* .btn-style-border {
  width: 100%;
  height: 4rem;
  border: 0.2rem solid var(--primary-color)!important;
  background-color: transparent;
  color: var(--primary-color)!important;
  font-size: 1.8rem!important;
  font-weight: 400!important;
  border-radius: 0.3rem!important;
}

.btn-style-border:hover {
  color: var(--primary-color);
  background-color: transparent;
  box-shadow: 0 0.5rem 0.5rem var(--primary-color)!important;
  border: none!important;
  border-radius: 0.3rem!important;
} */

 .navbar-toggler-icon i{
  font-size: 2rem;
  color: var(--sec-color)!important;
}
/*sgm*/
.search-bar{
  .search-bar {
    width: 200px; /* Adjust the width as per your requirement */
    padding: 0.375rem 0.75rem; /* Adjust padding as per your requirement */
    font-size: 14px; /* Adjust font size as per your requirement */
}

}
/* --------------------------------- Navbar ends
                        -------------------------------------------------------*/

                        .pastevents-container .row{
                          margin-top: 40px;
                        }
                        .form-label{
                          font-size: 1.2rem;
                          color: var(--sec-color);
                        }
                        .sgmtext{
                          font-size: 1.5rem;
                          color: #000;
                        }
/* --------------------------------- Hero sEction  starts
                        -------------------------------------------------------*/
                        header {
                          background-color: var(--bgcolor);
                          /* min-height: 82vh!important; */
                          width: 100%;
                        }

                        .main-hero-container {
                          height: 70vh;
                          display: flex;
                        }

                        .main-hero-container .btn-style-more{
                          border: 2px solid var(--sec-color);
                          padding: 10px;
                          margin: 2rem 0 0 0;
                          text-align: center;
                          font-weight: normal;
                          font-family: 'Signika Negative', sans-serif;
                          font-size: 2rem;
                          color: #000;
                          border-radius: 5rem;
                        }
                        .scroll-icon{
                          font-size: 6rem;
                          color: var(--sec-color);
                                                    animation: up-down 2s ease-in-out infinite alternate-reverse;
                        }
.navbar{
  border-bottom: 2px var(--sec-color);
  border-bottom-style: dashed;
  box-shadow: 0 0.3rem 0.3rem var(--sec-color)!important;
}
                        .main-hero-container .btn-style-more:hover{
                          color: #000;
                          background-color: transparent;
                          box-shadow: 0 0.5rem 0.5rem var(--sec-color)!important;
                          border: none!important;
                        }
                        .pastevents-container .btn-style-more{
                          border: 2px solid var(--sec-color);
                          padding: 10px;
                          margin: 2rem 0 0 0;
                          text-align: center;
                          font-weight: normal;
                          font-family: 'Signika Negative', sans-serif;
                          font-size: 1.8rem;
                          color: #000;
                          border-radius: 5px;
                          text-decoration: none!important;
                        }

                        .pastevents-container .btn-style-more:hover{
                          color: #000;
                          background-color: transparent;
                          box-shadow: 0 0.5rem 0.5rem var(--sec-color)!important;
                          border: none!important;
                        }

                        .main-hero-container h1 {
                          text-transform: capitalize;
                          font-weight: bolder;
                          font-family: 'Roboto Slab', sans-serif;
                        }

                        .main-hero-container h1.display-1{
                          font-size: 3vw;
                          color: #000;

                        }
                        .main-hero-container h1.display-2{
                          font-size: 4.3vw;
                          /* background: -webkit-radial-gradient(#fbb03b 0%, #f15a24 52.7%, #fe0904 100%);
                          background-clip: text; */
                          color: var(--sec-color);
                          /* -webkit-text-fill-color: transparent;Fcopy */
                        }

                        .main-hero-container .main-hero-para {
                          font-size: 2rem;
                          color: var(--text-color);
                          margin: 0;
                          font-weight: 400;
                          width: 75%;
                          font-family: 'Signika Negative', sans-serif;
                        }

                        .main-hero-container i{
                          color: var(--primary-color);
                          font-size: 5rem;
                          cursor: pointer;
                          margin-bottom: 2rem;
                        }

                        /* right side of hero section  */
@media screen and (max-width: 992px){
  .main-hero-container h1.display-1{
    font-size: 8vw;
    color: #000;

  }
  .main-hero-container h1.display-2{
    font-size: 10vw;
    /* background: -webkit-radial-gradient(#fbb03b 0%, #f15a24 52.7%, #fe0904 100%);
    background-clip: text; */
    color: var(--sec-color);
    /* -webkit-text-fill-color: transparent;Fcopy */
  }
}
                        @media screen and (min-width: 992px){
                          .main-herosection-images {
                            position: relative;
                            width: 40vw!important;
                            /* width: 620px!important; */
                            height: auto!important;
                          }
                          .main-hero-img1 {
                            position: absolute;
                            z-index: 2;
                            width: 100%!important;
                            height: 100%!important;
                          }
                           .main-hero-img2{
                            /* opacity: 0.5; */
                            width: 80%;
                            height: auto!important;
                          }
                        }

                        @media screen and (max-width: 992px) {
                          .main-hero-container{
                            height: 90%!important;
                          }
                          .main-herosection-images{
                            margin-top: 4rem!important;
                          }
                          .main-hero-img1 {
                            position: absolute;
                            z-index: 2;
                            width: 80%!important;
                            height: auto!important;
                          }
                           .main-hero-img2{
                            /* opacity: 0.5; */
                            width: 70%;
                            height: auto!important;
                          }
                          .main-hero-container .right{
                            margin: 2rem 0rem;
                            justify-content: center!important;
                            align-items: center!important;
                            text-align: center!important;
                          }
                        }

                        .main-hero-img2{
                          animation: up-down 2s ease-in-out infinite alternate-reverse both;
                      }

                      @keyframes up-down {
                          0% {
                              transform: translateY(10px);
                          }
                          100% {
                              transform: translateY(-10px);
                          }
                      }


                        /* --------------------------------- Hero sEction  ends
                                                -------------------------------------------------------*/

/* ---------------------------------video Testimonials Section starts
                                                -------------------------------------------------------*/
.video_testimonial-container{
  margin-top: 5rem;
}
.test_video{
  margin-top: 3rem;
}
.v_sub-heading{
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  font-weight: normal;
  font-family: 'Signika Negative', sans-serif;
}

.instructor_vt{
  justify-content: center;
  text-align: center;
  font-weight: lighter;
  font-family: 'Signika Negative', sans-serif;
  font-size: 1.5rem;
  color: var(--text-color);
}
/* ---------------------------------video Testimonials Section ends
                                                -------------------------------------------------------*/

/*arrow*/
.center{
  pointer: absolute;
  top:50%;
  left:50%;
  transform: translate(50%, -50%);
  width: 0;
  height: 160px;
  border-left: 6px dashed rgba(255,255,255,.1);

}
.arrow{
  content: '';
  pointer: absolute;
  bottom: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-bottom: 6px solid #fff;
  border-right: 6px solid #ff;
  transform: rotate(45deg);
}
/* --------------------------------- Gallery Section ends
                                                -------------------------------------------------------*/

                                                .gallery-tabs{
                                                  margin-top: 5rem;
                                                }

                                                .btn-select{
                                                  width: 15rem!important;
                                                  /* height: 4rem!important; */
                                                  border-radius: 0.3rem!important;
                                                  color: var(--primary-color);
                                                  text-transform: capitalize;
                                                  font-size: 1.5rem!important;
                                                  margin-right: 1rem!important;
                                                  margin-left: 1rem!important;
                                                  margin-top: 5rem!important;
                                                  margin-bottom: 2rem!important;
                                                  text-align: center;
                                                  justify-content: center;
                                                  align-items: center;
                                               }

                                               .gallery-tabs .active{
                                                box-shadow: 0 0.2rem 0.5rem rgba(183, 171, 255, 0.5)!important;
                                               }

                                               .btn-select:hover {
                                                border: 0.1rem solid var(--primary-color)!important;
                                                background-color: transparent!important;
                                                color: var(--primary-color)!important;
                                              }


                                              .image-card {
                                                padding: 2px!important;
                                                margin: 5px!important;
                                                border: 1px solid var(--primary-color);
                                                border-radius: 2px;
                                                height: auto;
                                                width: 300px!important;
                                              }

                                              .gallery-tabs img{
                                                width: 100%;
                                                height: 100%;
                                                padding: 0.8rem;
                                              }

                                              @media (max-width: 992px){
                                                .tags{
                                                  flex-direction: column;
                                                }
                                              }
                                                                                              /* --------------------------------- Gallery Section ends
                                                                                              -------------------------------------------------------*/
                                                                                              /*sgm cards*/
                                                                                              .sgm_card{
                                                                                                border: 2px solid var(--sec-color);
                                                                                                width: 80%;
                                                                                                background: var(--light-color);
                                                                                              }
                                                                                              .photo{
                                                                                                border: 2px solid var(--sec-color);
                                                                                                padding: 15px;
                                                                                                border-radius: 50%;
                                                                                                margin-top: -20px;
                                                                                                background: var(--bgcolor);

                                                                                              }
                                                                                              .photo:hover{
                                                                                                background: #fff;
                                                                                              }
                                                                                              .photo .icon{
                                                                                                color: #000;
                                                                                                width: 25px;
                                                                                                height: 25px;
                                                                                              }
                                                                                              .tablesgm .icon{
                                                                                                color: #000;
                                                                                                width: 25px;
                                                                                                height: 25px;
                                                                                              }
                                                                                              /* --------------------------------- Footer  Section starts
                        -------------------------------------------------------*/

                        footer {
                          padding: 5rem 0 2rem 0;
                          background-color: var(--footer-background)!important;
                        }

                        footer .copyright{
                          border-bottom: solid 2px var(--footer-text);
                          margin-bottom: 0!important;
                        }
                        .footer-row ul li {
                          list-style: none!important;
                          font-weight: lighter;
                          font-family: 'Signika Negative', sans-serif;
                          font-size: 1.5rem;
                          cursor: pointer;
                          margin: 1rem 0;
                        }

                        .footer-row ul li a{
                          text-decoration: none;
                          font-weight: lighter;
                          font-family: 'Signika Negative', sans-serif;
                          font-size: 1.5rem!important;
                          color: var(--footer-text);
                        }

                        footer li a:hover {
                          color: #fff!important;
                        }

                        footer span{
                          font-weight: lighter;
                          font-family: 'Signika Negative', sans-serif;
                          font-size: 1.5rem!important;
                          color: var(--footer-text);
                        }

                        footer p{
                          font-weight: lighter;
                          font-family: 'Signika Negative', sans-serif;
                          font-size: 1.5rem!important;
                          color: var(--footer-text);
                        }

                        footer p:hover{
                          color: #fff;
                        }

                        footer span:hover{
                          color: #fff;
                        }
                        footer .fontawesome-style {
                          font-size: 2.3rem;
                          color: var(--footer-text);
                          background-color: transparent;
                          width: 0rem;
                          height: 0rem;
                          margin: 2rem 0;
                          cursor: pointer;
                          text-decoration: none;
                        }

                        footer h2{
                          color: #fff;
                          font-size: 2rem;
                          font-weight: normal;
                          font-family: 'Signika Negative', sans-serif;
                        }
                        footer a{
                          text-decoration: none!important;
                        }

                        .footer-main-hero-para{
                          font-size: 2rem;
                          font-weight: normal;
                          font-family: 'Signika Negative', sans-serif;
                          color: var(--footer-text);
                        }

                        footer .fontawesome-style:hover {
                          transition: all 1s;
                          animation: rotatefont 1s linear infinite alternate-reverse;
                          color: #fff;
                        }

                        @keyframes rotatefont {
                          0% {
                            transform: scale(0.6);
                          }
                          100% {
                            transform: scale(1.2);
                          }
                        }

                        footer .main-hero-para {
                          margin: 0;
                        }

                        /* --------------------------------- Footer  Section ends
                                                -------------------------------------------------------*/


/*form*/
.form-group{
  width:100%;
  margin: auto;
  padding: 50px;
  background: beige;
  border: 2px solid var(--sec-color);
  border-radius: 15px;
  background: var(--light-color);
}
@media screen and (max-width: 992px){
  .form-group{
    margin: 40px 0px;
  }
}

.tablesgm .sgmdata{
  font-size: 1.5rem;
  color: var(--sec-color);
}
